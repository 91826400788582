<template>
  <header class="header">
    <div class="div-header-img">
      <div class="logos" v-if="organizationComputed.hasOrganizationMais">
        <img
          style="width: 50%"
          src="../images/mais-logo.svg"
          alt="Mapa do Impacto Social"
        />
      </div>
      <img
        v-if="!organizationComputed.hasOrganizationMais"
        src="./../images/logo-seall.png"
        alt="Logo SEALL"
        class="img-logo"
      />
      <div class="div-header-links">
        <a
          href="/organization"
          class="header-link"
          v-bind:class="{ 'seall-active': 'organization' === currentRouteName }"
        >
          {{ $t("header.perfil") }}
        </a>
        <!--<a
          href="/questions"
          class="header-link"
          v-bind:class="{ 'seall-active': 'questions' === currentRouteName }"
        >
          {{ $t("header.jornada") }}
        </a>-->
        <a
          href="/legacy"
          class="header-link"
          v-bind:class="{ 'seall-active': 'legacy' === currentRouteName }"
        >
          {{ $t("header.ods") }}
        </a>
        <a
          href="/map"
          class="header-link"
          v-bind:class="{ 'seall-active': 'map' === currentRouteName }"
        >
          {{ $t("header.impacto") }}
        </a>
        <a
          href="/esg"
          class="header-link"
          v-bind:class="{ 'seall-active': 'esg' === currentRouteName }"
        >
          {{ $t("header.esg") }}
        </a>
        <a @click.prevent="logout()" class="header-link cursor-pointer">
          {{ $t("main.logout") }}
        </a>
      </div>
    </div>

    <div class="right-side">
      <div class="header-div-langs">
        <img src="./../../langs/pt.png" v-on:click="set_lang(langs[2][0])" />
        <img src="./../../langs/en.png" v-on:click="set_lang(langs[0][0])" />
        <img src="./../../langs/es.png" v-on:click="set_lang(langs[1][0])" />
      </div>

      <div class="div-header-photo">
        <i class="material-icons">search</i>
        <img
          :src="
            organizationComputed && organizationComputed.photoPath
              ? organizationComputed.photoPath
              : require('./../images/foto_exe.png')
          "
          class="header-photo"
        />
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "header",
  props: ["organization"],
  data() {
    return {
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name.toLowerCase();
    },
    organizationComputed() {
      if (this.organization != null && this.organization.id != null) {
        return this.organization;
      }
      return JSON.parse(localStorage.getItem("organization"));
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    set_lang(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
  flex-wrap: wrap;
  .div-header-img {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .logos {
      display: flex;
      align-items: center;
      img {
        width: 30%;
        padding: 1rem;
      }
    }
  }
}

.div-header-links {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding: 2rem;
  .header-link {
    color: #a3a3a3;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    padding: 0vh 2vw;
    text-decoration: none;
  }
}

.right-side {
  display: flex;
  flex-direction: row;
  .header-div-langs {
    margin: 0vh 0.5vw;
    display: flex;
    align-items: center;
    img {
      margin: 0vh 0.25vw;
      cursor: pointer;
    }
  }
  .div-header-photo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    i {
      padding: 0vh 1vw 0vh 3vw;
    }
    img.header-photo {
      border-radius: 100%;
      overflow: hidden;
      max-width: 71px;
      max-height: 71px;
    }
  }
}

@media screen and (max-width: 1038px) {
  .right-side {
    width: 100%;
    justify-content: center;
    padding: 1rem 0 1rem 0;
  }
}
</style>