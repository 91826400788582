<template>
  <div id="questions">
    <loading :active="perguntas.length <= 0" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />

    <seall-header></seall-header>

    <main class="main-questions">
      <carousel-cards></carousel-cards>

      <div class="div-questions-menu">
        <h2 style="text-transform: uppercase">{{ $t("questions.title") }}</h2>
        <h3>{{ $t("questions.subtitle") }}</h3>
        <div class="div-questions-abas">
          <span v-if="checked_status(-1)">{{
            $t("questions.index.tipo")
          }}</span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.tipo") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(0)">{{ $t("questions.index.nome") }}</span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.nome") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(1)">
            {{ $t("questions.index.missao") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.missao") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(2)">
            {{ $t("questions.index.paraquem") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.paraquem") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(3)">
            {{ $t("questions.index.problema") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.problema") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(4)">
            {{ $t("questions.index.solucao") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.solucao") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(5)">
            {{ $t("questions.index.atividades") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.atividades") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(6)">
            {{ $t("questions.index.efeitos") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.efeitos") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(7)">
            {{ $t("questions.index.resultados") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.resultados") }}
          </span>
          <span>&#62;</span>
          <span v-if="checked_status(8)">
            {{ $t("questions.index.impacto") }}
          </span>
          <span v-else style="color: #533a8b">
            {{ $t("questions.index.impacto") }}
          </span>
        </div>
        <div
          class="div-questions-bar"
          :style="{ width: this.porcetagem }"
        ></div>
      </div>

      <div class="div-questions-questions">
        <div class="questions-questions-column1">
          <img src="./../images/ods-circle.svg" alt />
          <p>{{ $t("questions.info") }}</p>
        </div>

        <div class="questions-questions-column2">
          <div class="questions-form-perguntas">
            <p>{{ $t("questions.questions.question.question") }}</p>
            <div class="div-radio">
              <input
                class="input-radio"
                type="radio"
                id="data_style"
                v-model="organization.aboutWho"
                @change="save_toggle(0, { anwser: null }, 'radio')"
                name="1"
                :label="$t('questions.questions.question.option1')"
                value="Sua organização"
              />
              <input
                class="input-radio"
                type="radio"
                id="data_style"
                v-model="organization.aboutWho"
                @change="save_toggle(0, { anwser: null }, 'radio')"
                name="1"
                :label="$t('questions.questions.question.option2')"
                value="Uma iniciativa da organização"
              />
            </div>
          </div>

          <form class="questions-form-perguntas">
            <div
              class="pergunta"
              v-for="(pergunta, index) in perguntas"
              v-bind:key="pergunta"
            >
              <p>
                {{ $t("questions.questions.question" + index + ".question") }}
                <Popper v-if="index > 1">
                  <i class="material-icons">help</i>
                  <template #content>
                    <div class="tooltip">
                      {{
                        $t("questions.questions.question" + index + ".items")
                      }}
                    </div>
                    <p class="tooltip">{{ $t("questions.how_to_use") }}</p>
                  </template>
                </Popper>
              </p>
              <div v-if="index > 1">
                <chip
                  v-model:chips="pergunta.answer"
                  :options="{ maxLength: 150 }"
                  @change="save_toggle(index, pergunta, 'pergunta')"
                ></chip>
              </div>
              <div v-else>
                <input
                  required
                  class="questions-questions-input"
                  :style="{ border: pergunta.required }"
                  v-model="pergunta.answer"
                  type="text"
                  :name="pergunta.name"
                  :id="pergunta.name"
                  @change="save_toggle(index, pergunta, 'pergunta')"
                  :placeholder="
                    $t('questions.questions.question' + index + '.placeholder')
                  "
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <footer class="footer-questions">
        <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />

        <div id="openModal" class="modalDialog">
          <div>
            <img src="./../images/card-icon1.svg" alt />
            <h2>{{ $t("questions.modal.title") }}</h2>
            <h3>{{ $t("questions.modal.subtitle") }}</h3>
            <p>{{ $t("questions.modal.info") }}</p>
            <div class="modalDialog-btns">
              <a href="#close" title="Close" class="close">
                <button type="button" class="questions-modal-button-back">
                  {{ $t("questions.modal.button_back") }}
                </button>
              </a>
              <router-link to="/organization" class>
                <button type="button" class="questions-modal-button-continue">
                  {{ $t("questions.modal.button_go") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="div-questions-footer-button">
          <powered-by></powered-by>
          <a href="#openModal">
            <button
              v-if="save_data"
              type="button"
              class="questions-footer-button"
              :disabled="!save_data"
            >
              {{ $t("questions.button_bottom") }} &#10132;
            </button>
            <button
              v-else
              type="button"
              class="questions-footer-button-disable"
              :disabled="!save_data"
            >
              {{ $t("questions.button_bottom") }} &#10132;
            </button>
          </a>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Popper from "vue3-popper";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service";
import Chip from "./Chip.vue";
import SeallHeader from "./Header.vue";
import CarouselCards from "./CarouselCards.vue";
import PoweredBy from "./PoweredBy.vue";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
export default {
  name: "questions",
  data() {
    return {
      valor: 4.5,
      perguntas: [],
      save_data: false,
      data: {},
      count_respostas: 0,
      porcetagem: "0%",
      organization: {
        style: "",
        quiz: [],
        organizationInitiative: "",
        mission: "",
        forWho: "",
        knowledgeProblem: "",
        solution: "",
        activity: "",
        effect: "",
        result: "",
        impact: "",
      },
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
    };
  },
  mounted() {
    const cogId = AuthService.getCogId();
    OrganizationService.getByCreatedBy(cogId)
      .then((org) => {
        this.load(org.data);
      })
      .catch((error) => {
        if (error.response.data.msg === "Organization not found") {
          OrganizationService.create({}).then((org) => {
            this.load(org.data);
          });
        }
      });
    if (window.screen.availWidth < 1025) this.valor = 1.25;
    else this.valor = 4.5;
  },
  methods: {
    checked_status(index) {
      if (index === -1) {
        return this.organization.aboutWho === "";
      }
      if (this.perguntas[index]) {
        return !(
          this.perguntas[index].answer != "" ||
          this.perguntas[index].answer.length > 0
        );
      }
      return true;
    },
    save_toggle(index, pergunta, type) {
      if (
        type != "radio" &&
        typeof pergunta.answer === "string" &&
        pergunta.answer == ""
      ) {
        pergunta.required = "1px solid red !important";
        return;
      }

      pergunta.required = "1px solid #c9baeb !important";

      if (type == "radio" && index == 0) {
      } else if (type == "pergunta" && index < 8) {
      } else {
        this.save_data = true;
      }

      const perguntasRespondidas = this.perguntas.filter(
        (p) => p.answer != "" || p.answer.length > 0
      ).length;

      if (this.organization.aboutWho != "") {
        this.porcetagem = (perguntasRespondidas + 1) * 10 + "%";
      } else {
        this.porcetagem = perguntasRespondidas * 10 + "%";
      }

      this.organization.style = document.getElementById("data_style").value;
      this.organization.organizationInitiative = this.perguntas[0].answer;

      this.organization.mission = this.perguntas[1].answer;

      this.organization.quiz = [];
      for (let i = 2; i <= 8; i++) {
        let source = "";
        switch (i) {
          case 2:
            source = "forWho";
            break;
          case 3:
            source = "knowledgeProblem";
            break;
          case 4:
            source = "solution";
            break;
          case 5:
            source = "activity";
            break;
          case 6:
            source = "effect";
            break;
          case 7:
            source = "result";
            break;
          case 8:
            source = "impact";
            break;
          default:
            break;
        }
        this.perguntas[i].answer.forEach((asw, ai) => {
          if (!asw.hasOwnProperty("key")) {
            this.perguntas[i].answer[ai] = {
              key: uuidv4(),
              value: asw.value,
              source: source,
            };
          }
        });
        this.organization.quiz = this.organization.quiz.concat(
          this.perguntas[i].answer
        );
      }

      if (this.organization.id != null) {
        OrganizationService.update(
          this.organization.id,
          this.organization
        ).then((org) => (this.organization = org.data));
      } else {
        OrganizationService.create(this.organization).then(
          (org) => (this.organization = org.data)
        );
      }
    },
    load(org) {
      this.organization = org;
      OrganizationService.getQuestions().then((q) => {
        this.perguntas = q.data;

        this.perguntas.forEach((p) => {
          switch (p.name) {
            case "data_style":
              p.answer = this.organization.style
                ? this.organization.style.splice("|")
                : [];
              break;
            case "data_nome":
              p.answer = this.organization.organizationInitiative;
              break;
            case "data_missao":
              p.answer = this.organization.mission;
              break;
            case "data_quem":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "forWho"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter((q) => q.source === "forWho")
                  )
                : [];
              break;
            case "data_entendimento":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "knowledgeProblem"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter(
                      (q) => q.source === "knowledgeProblem"
                    )
                  )
                : [];
              break;
            case "data_solucoes":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "solution"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter(
                      (q) => q.source === "solution"
                    )
                  )
                : [];
              break;
            case "data_atividades":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "activity"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter(
                      (q) => q.source === "activity"
                    )
                  )
                : [];
              break;
            case "data_efeitos":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "effect"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter((q) => q.source === "effect")
                  )
                : [];
              break;
            case "data_resultados":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "result"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter((q) => q.source === "result")
                  )
                : [];
              break;
            case "data_impacto":
              p.answer = this.organization.quiz.filter(
                (q) => q.source === "impact"
              )
                ? _.cloneDeep(
                    this.organization.quiz.filter((q) => q.source === "impact")
                  )
                : [];
              break;
            default:
              break;
          }

          if (p.answer != "" || p.answer.length > 0) {
            this.count_respostas++;
          }
        });

        if (this.organization.aboutWho != "") {
          this.count_respostas++;
        }

        this.porcetagem = this.count_respostas * 10 + "%";

        if (this.porcetagem === "100%") {
          this.save_data = true;
        }
      });
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Chip,
    Loading,
    Popper,
    SeallHeader,
    PoweredBy,
    CarouselCards,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.tooltip {
  font-size: 14px !important;
  color: #fff !important;
}

.material-icons {
  color: #000000;
}

.main-questions {
  background-color: #e5e5e5;
  position: relative;
}

.div-questions-menu {
  background-color: #ffffff;
  h2 {
    font-family: Poppins-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 133%;
    text-align: center;
    color: #533a8b;
    margin: 0vh 0vw;
    padding: 5vh 0vw 0vh 0vw;
  }
  h3 {
    color: #ffffff;
    font-family: NunitoSans-Regular;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    color: #a3a3a3;
    text-align: center;
    margin: 0vh 0vw;
    padding: 2vh 0vw 0vh 0vw;
  }
  span {
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #9d9d9c;
    margin: 0vh 0vw;
    padding: 4vh 0vw 1vh 0vw;
  }
  .div-questions-abas {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3vh 10vw;
  }
  .div-questions-bar {
    height: 1vh;
    background-color: #00b9bd;
  }
}

.div-questions-questions {
  display: flex;
  flex-direction: row;
  .questions-questions-column1 {
    padding: 10vh 5vw 5vh 10vw;
    width: 33%;
    p {
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 2vh;
      line-height: 170%;
      color: #000000;
      opacity: 0.4;
      margin: 0 !important;
      margin-top: 1rem !important;
      text-align: justify;
    }
  }

  .questions-questions-column2 {
    padding: 10vh 0vw 5vh 10vw;
    width: 77%;

    p {
      color: #533a8b;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      padding: 0vh 0vw;
    }

    input {
      background: #ffffff;
      border: 1px solid #c9baeb;
      border-radius: 4px;
      font-family: Lato-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      width: 90%;
      padding: 1vh 1vw;
    }

    div.questions-form-perguntas {
      margin-bottom: 1rem;
    }

    form {
      .pergunta {
        margin-bottom: 1rem;
      }
    }

    .div-radio {
      display: flex;
      flex-direction: row;
      padding: 0vh 0vw;
    }

    .div-radio .input-radio {
      appearance: none;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #533a8b;
      padding: 1vh 0vh;
      background: #e5e5e5;
      color: #533a8b;
      font-size: 16px;
      font-family: NunitoSans-Regular;
      transition: all 200ms linear;
      width: 20vw;
      margin: 0vh 2vw 0vh 0vw;
    }

    .div-radio .input-radio:checked {
      background: #492e85;
      color: #fff;
    }

    .div-radio .input-radio:before {
      content: attr(label);
      display: inline-block;
      text-align: center;
      width: 100%;
    }
    button {
      color: #2f1764;
      background-color: #e5e5e5;
      border-color: #2f1764;
      border-radius: 5px;
      border-style: solid;
      border-width: thin;
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      margin: 0vh 0vw;
      padding: 1vh 2vw;
    }
  }
}

.footer-questions {
  height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.footer-questions svg {
  width: 5%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.footer-questions rect {
  width: 100%;
  height: 1vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-questions-footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0vh 20vw;
}

.questions-footer-button {
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 3vw;
}

.questions-footer-button-disable {
  color: #ffffff;
  background-color: #9ea4ac;
  border-color: #9ea4ac;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 3vw;
}

.modalDialog {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  opacity: 1;
  pointer-events: auto;
}

.modalDialog > div {
  width: 30vw;
  margin: 10vh 35vw;
  position: relative;
  padding: 5vh 2vw;
  border-radius: 10px;
  background: #fff;
  background: -moz-linear-gradient(#fff, #999);
  background: -webkit-linear-gradient(#fff, #999);
  background: -o-linear-gradient(#fff, #999);
}

.modalDialog img {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 15vh;
}

.modalDialog h2 {
  color: #492e85;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  margin: 0vh 0vw;
  padding-bottom: 5vh;
}

.modalDialog h3 {
  color: #32363b;
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0vh 0vw;
  padding-bottom: 2vh;
}

.modalDialog p {
  color: #707780;
  font-family: NunitoSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0vh 0vw;
  padding-bottom: 3vh;
}

.modalDialog-btns {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.questions-modal-button-back {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 1vw;
  padding: 1vh 2vw;
}

.questions-modal-button-continue {
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 2vw;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .div-questions-menu {
    .div-questions-abas {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 3vh 10vw;
    }
    span {
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      color: #9d9d9c;
      margin: 0 0.25rem;
      padding: 0.25rem 0 0.25rem 0;
    }
  }

  .div-questions-questions {
    display: flex;
    flex-direction: column;
    width: 100%;

    .questions-questions-column1 {
      display: flex;
      flex-direction: row;
      margin: 0vh 0vw;
      padding: 1vh 0vw;
      align-content: center;
      width: 100%;
      justify-content: center;
      margin-bottom: 1rem;
      p {
        flex-wrap: wrap;
        padding: 0vh 0vw 0vh 5vw;
        width: 70%;
        display: flex;
        align-items: center;
      }

      img {
        width: 20%;
      }
    }

    .questions-questions-column2 {
      width: 100%;
      padding: 0vh 0vw 10vh;

      & input {
        width: 90%;
      }
      & .chip-container input {
        width: 90%;
        margin-left: 5%;
      }

      .div-radio {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-content: center;
        text-align: center;
        padding: 0vh 5vw;
        width: 100%;
      }

      .div-radio .input-radio {
        display: flex;
        align-items: center;
        width: 45vw;
      }

      .questions-form-perguntas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-content: center;
        text-align: center;
        width: 100%;
      }

      .questions-form-perguntas p {
        padding: 0vh 0vw 0vh 5vw;
        text-align: left;
      }
    }
  }

  .modalDialog > div {
    width: 70vw;
    margin: 10vh 10vw;
    position: relative;
    padding: 5vh 5vw 3vh;
  }
}
</style>